import { extendTheme } from '@chakra-ui/react'

const activeLabelStyles = {
	transform: 'scale(0.85) translateY(-24px)',
}

export const theme = extendTheme({
	colors: {
		primary: {
			50: 'rgba(14, 74, 74, 0.1)',
			100: 'rgba(14, 74, 74, 0.2)',
			200: 'rgba(14, 74, 74, 0.3)',
			300: 'rgba(14, 74, 74, 0.4)',
			400: 'rgba(14, 74, 74, 0.5)',
			500: 'rgba(14, 74, 74, 0.6)',
			600: 'rgba(14, 74, 74, 0.7)',
			700: 'rgba(14, 74, 74, 0.8)',
			800: 'rgba(14, 74, 74, 0.9)',
			900: 'rgba(14, 74, 74, 1)',
		},
	},
	styles: {
		global: {
			body: {
				bg: 'primary.900',
			},
		},
	},
	components: {
		Form: {
			variants: {
				floating: {
					container: {
						_focusWithin: {
							label: {
								...activeLabelStyles,
							},
						},
						'input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label, select:not(:placeholder-shown) + label':
							{
								...activeLabelStyles,
							},
						label: {
							top: 0,
							left: 0,
							zIndex: 2,
							position: 'absolute',
							backgroundColor: 'white',
							pointerEvents: 'none',
							mx: 3,
							px: 1,
							my: 2,
							transformOrigin: 'left top',
						},
					},
				},
			},
		},
	},
})
