import {
	Box,
	Button,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	Input,
	useToast,
	VStack,
} from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import React from 'react'
import InputMask from 'react-input-mask'
import { useNavigate } from 'react-router'
import * as Yup from 'yup'
import { useStores } from '../store/store_context'
import stars from './../images/stars_background.png'

// Определяем схему валидации с помощью Yup
const validationSchema = Yup.object({
	phone: Yup.string()
		.matches(
			/^7\s\(\d{3}\)\s\d{3}-\d{2}-\d{2}$/,
			'Неверный формат телефона'
		)
		.required('Телефон обязателен'),
	password: Yup.string()
		.min(6, 'Пароль должен быть минимум 6 символов')
		.required('Пароль обязателен'),
})

const LoginPage = () => {
	const navigate = useNavigate()
	const toast = useToast()
	const { pageStore } = useStores()

	const handleSubmit = async values => {
		values.phone = values.phone.replace(/\D/g, '')
		console.log('Данные формы:', values)
		const [result, response] = await pageStore.signIn(values)
		if (response.ok) {
			pageStore.getCurrentAuthUser()
			pageStore.token && navigate('/main')
		} else {
			toast({
				title: 'Ошибка авторизации!',
				status: 'error',
				duration: 1000,
				isClosable: true,
			})
		}
	}

	return (
		<VStack
			width={'100%'}
			minH={'100vh'}
			backgroundColor={'rgba(14, 74, 74, 1)'}
			backgroundImage={stars}
			color={'white'}
		>
			<Box maxW='md' mx='auto' mt='10'>
				<Heading
					as='h2'
					size='lg'
					mb='6'
					textAlign='center'
					fontFamily={'Montserrat Alternates'}
				>
					Вход
				</Heading>
				<Formik
					initialValues={{ phone: '', password: '' }}
					validationSchema={validationSchema}
					onSubmit={handleSubmit}
				>
					{({ values, errors, touched, setFieldValue }) => (
						<Form>
							<FormControl
								isInvalid={errors.phone && touched.phone}
								mb='4'
							>
								<FormLabel htmlFor='phone'>Телефон</FormLabel>
								<Field name='phone'>
									{({ field }) => (
										<InputMask
											mask='7 (999) 999-99-99'
											value={values.phone}
											onChange={e => {
												setFieldValue(
													'phone',
													e.target.value,
													false
												)
											}}
											onBlur={e => {
												setFieldValue(
													'phone',
													e.target.value
												)
											}}
										>
											{inputProps => (
												<Input
													{...inputProps}
													placeholder='7 (___) ___-__-__'
													id='phone'
													type='tel'
												/>
											)}
										</InputMask>
									)}
								</Field>
								{/* <Field
									as={Input}
									id='phone'
									name='phone'
									type='tel'
									placeholder='7 (999) 999-99-99'
									value={values.phone} // Управляемое поле
									onChange={e => {
										const formatted = normalizePhoneNumber(
											e.target.value
										)
										setFieldValue('phone', formatted, false) // Обновляем без валидации
									}}
								/> */}
								<FormErrorMessage>
									{errors.phone}
								</FormErrorMessage>
							</FormControl>

							<FormControl
								isInvalid={errors.password && touched.password}
								mb='4'
							>
								<FormLabel htmlFor='password'>Пароль</FormLabel>
								<Field
									as={Input}
									id='password'
									name='password'
									type='password'
									placeholder='Введите пароль'
								/>
								<FormErrorMessage>
									{errors.password}
								</FormErrorMessage>
							</FormControl>

							<Button
								colorScheme='teal'
								type='submit'
								width='full'
								mt='4'
							>
								Войти
							</Button>
						</Form>
					)}
				</Formik>
			</Box>
		</VStack>
	)
}

export default LoginPage
