import {
	Button,
	FormControl,
	FormLabel,
	HStack,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	useDisclosure,
	useToast,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { useStores } from '../../store/store_context'

const CreateHookahModal = ({ updateHookahs }) => {
	const { isOpen, onOpen, onClose } = useDisclosure()
	const [hookahData, setHookahData] = useState({
		name: '',
		description: '',
		price: '',
		preparation_time: '',
		image_id: 3, // По умолчанию
	})
	const toast = useToast()

	const handleChange = e => {
		const { name, value } = e.target
		setHookahData(prevData => ({
			...prevData,
			[name]: value,
		}))
	}

	const { pageStore } = useStores()

	const handleSubmit = async () => {
		try {
			const response = await fetch(
				'https://enot-restolounge.ru:8888/hookahs/',
				{
					method: 'POST',
					headers: {
						accept: 'application/json',
						Authorization: `Bearer ${pageStore.token}`, // Используйте ваш токен
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						...hookahData,
						id: 0, // По умолчанию
					}),
				}
			)

			if (!response.ok) {
				throw new Error('Error creating hookah')
			}

			const result = await response.json()
			updateHookahs() // Загрузить список кальянов после добавления
			toast({
				title: 'Hookah created successfully.',
				status: 'success',
				duration: 3000,
				isClosable: true,
			})
			onClose() // Закрываем модалку после успешного создания
		} catch (error) {
			toast({
				title: 'Error creating hookah.',
				description: error.message,
				status: 'error',
				duration: 3000,
				isClosable: true,
			})
		}
	}

	return (
		<HStack align={'center'}>
			<Button onClick={onOpen} colorScheme='blue'>
				Создать кальян
			</Button>

			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent
					fontFamily={
						'"Montserrat Alternates", sans-serif !important'
					}
				>
					<ModalHeader>Создать кальян</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<FormControl mb={4}>
							<FormLabel>Название кальяна</FormLabel>
							<Input
								placeholder='Введите название кальяна'
								name='name'
								value={hookahData.name}
								onChange={handleChange}
							/>
						</FormControl>

						<FormControl mb={4}>
							<FormLabel>Описание</FormLabel>
							<Input
								placeholder='Введите описание кальяна'
								name='description'
								value={hookahData.description}
								onChange={handleChange}
							/>
						</FormControl>

						<FormControl mb={4}>
							<FormLabel>Цена</FormLabel>
							<Input
								placeholder='Введите цену кальяна'
								name='price'
								type='number'
								value={hookahData.price}
								onChange={handleChange}
							/>
						</FormControl>

						<FormControl mb={4}>
							<FormLabel>Время приготовления (мин.)</FormLabel>
							<Input
								placeholder='Введите время приготовления'
								name='preparation_time'
								type='number'
								value={hookahData.preparation_time}
								onChange={handleChange}
							/>
						</FormControl>
					</ModalBody>

					<ModalFooter>
						<Button
							colorScheme='blue'
							mr={3}
							onClick={handleSubmit}
						>
							Создать кальян
						</Button>
						<Button variant='ghost' onClick={onClose}>
							Отмена
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</HStack>
	)
}

export default CreateHookahModal
