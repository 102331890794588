import { VStack } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useStores } from '../store/store_context'
import stars from './../images/stars_background.png'

const PrivateRoute = ({ children }) => {
	const { pageStore } = useStores()
	const navigate = useNavigate()
	useEffect(() => {
		if (!pageStore.token) {
			navigate('/')
		}
	})
	return (
		<VStack
			width={'100%'}
			minH={'100vh'}
			backgroundColor={'rgba(14, 74, 74, 1)'}
			backgroundImage={stars}
		>
			{children}
		</VStack>
	)
}

export default PrivateRoute
