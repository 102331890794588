import { EditIcon } from '@chakra-ui/icons'
import {
	Box,
	Button,
	Heading,
	IconButton,
	Image,
	Input,
	Table,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
	useDisclosure,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useStores } from '../../store/store_context'
import HallModal from './hall_modal' // Модальное окно для редактирования зала

const HallsTable = () => {
	const [sortField, setSortField] = useState(null) // Поле для сортировки
	const [sortOrder, setSortOrder] = useState('asc') // Направление сортировки
	const [searchTerm, setSearchTerm] = useState('') // Текст для поиска
	const [halls, setHalls] = useState([]) // Список залов
	const [selectedHallId, setSelectedHallId] = useState(null) // ID выбранного зала для редактирования
	const { isOpen, onOpen, onClose } = useDisclosure() // Управление состоянием модалки

	const { pageStore } = useStores()

	// Функция для получения данных о залах
	const fetchHalls = async () => {
		try {
			const response = await fetch(
				'https://enot-restolounge.ru:8888/halls/full',
				{
					headers: {
						accept: 'application/json',
					},
				}
			)

			if (response.ok) {
				const data = await response.json()
				setHalls(data)
			} else {
				console.error('Ошибка загрузки данных залов')
			}
		} catch (error) {
			console.error('Ошибка:', error)
		}
	}

	useEffect(() => {
		fetchHalls()
	}, [])

	// Функция для сортировки массива залов
	const sortHalls = (halls, field, order) => {
		return [...halls].sort((a, b) => {
			const fieldA = a[field]?.toString().toLowerCase() || ''
			const fieldB = b[field]?.toString().toLowerCase() || ''

			if (fieldA < fieldB) {
				return order === 'asc' ? -1 : 1
			}
			if (fieldA > fieldB) {
				return order === 'asc' ? 1 : -1
			}
			return 0
		})
	}

	// Фильтрация залов по имени
	const filteredHalls = halls.filter(hall => {
		const hallName = hall.name.toLowerCase()
		return hallName.includes(searchTerm.toLowerCase())
	})

	// Сортировка залов в зависимости от выбранного поля
	const sortedHalls = sortField
		? sortHalls(filteredHalls, sortField, sortOrder)
		: filteredHalls

	// Переключение сортировки
	const toggleSort = field => {
		if (sortField === field) {
			setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
		} else {
			setSortField(field)
			setSortOrder('asc')
		}
	}

	// Открытие модалки для редактирования зала
	const handleEditHall = hallId => {
		setSelectedHallId(hallId)
		onOpen()
	}

	return (
		<Box
			p={4}
			backgroundColor={'white'}
			width={'100%'}
			borderRadius={'12px'}
			overflowX={'scroll'}
			fontFamily='"Montserrat Alternates", sans-serif'
		>
			<Heading as='h2' size='lg' mb={4}>
				Таблица залов
			</Heading>

			{/* Поиск по имени зала */}
			<Input
				placeholder='Поиск по имени зала'
				mb={4}
				value={searchTerm}
				onChange={e => setSearchTerm(e.target.value)}
			/>

			<Table variant='simple'>
				<Thead>
					<Tr>
						<Th>
							<Button
								variant='link'
								onClick={() => toggleSort('name')}
							>
								Название зала{' '}
								{sortField === 'name' &&
									(sortOrder === 'asc' ? '↑' : '↓')}
							</Button>
						</Th>
						<Th>Изображение</Th>
						<Th>Действия</Th>
					</Tr>
				</Thead>
				<Tbody>
					{sortedHalls.map(hall => (
						<Tr key={hall.id}>
							<Td>{hall.name}</Td>
							<Td>
								<Image
									src={hall.url}
									alt={hall.name}
									boxSize='100px'
									objectFit='cover'
								/>
							</Td>
							<Td>
								<IconButton
									icon={<EditIcon />}
									onClick={() => handleEditHall(hall.id)}
									aria-label='Edit Hall'
								/>
							</Td>
						</Tr>
					))}
				</Tbody>
			</Table>

			{/* Модальное окно для редактирования зала */}
			{isOpen && (
				<HallModal
					isOpen={isOpen}
					onClose={onClose}
					hallId={selectedHallId}
				/>
			)}
		</Box>
	)
}

export default HallsTable
