import { VStack } from '@chakra-ui/react'
import { useStores } from '../../store/store_context'
import UserTable from './user_table'

const UsersTab = () => {
	const { pageStore } = useStores()
	return (
		<VStack width={'100%'}>
			<UserTable />
		</VStack>
	)
}

export default UsersTab
