import { ChakraProvider } from '@chakra-ui/react'
import { createHashRouter, RouterProvider } from 'react-router-dom'
import LoginPage from './pages/login_page'
import MainPage from './pages/main_page'
import PrivateRoute from './pages/private_page'
import { theme } from './theme'

const router = createHashRouter([
	{ element: <LoginPage />, path: '/' },
	{
		element: (
			<PrivateRoute>
				<MainPage />
			</PrivateRoute>
		),
		path: '/main',
	},
])
const App = () => {
	return (
		<ChakraProvider theme={theme}>
			<RouterProvider router={router} />
		</ChakraProvider>
	)
}

export default App
