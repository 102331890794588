import { DeleteIcon, EditIcon } from '@chakra-ui/icons' // Иконка редактирования
import {
	Box,
	Button,
	Center,
	Flex,
	FormControl,
	Heading,
	HStack,
	IconButton,
	Image,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Table,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	useDisclosure,
	useToast,
	VStack,
} from '@chakra-ui/react'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { formatPhoneNumber } from '../../helpers'
import { updateUser } from '../../requests'
import { useStores } from '../../store/store_context'
import FloatingInput from '../shared/FloatingInput'
import FloatingLabelSelect from '../shared/FloatingSelect'
import ImageUploader from '../shared/ImagePreview'
import CreateUserModal from './user_create_modal'

// Компонент для модального окна редактирования пользователя
const UserModal = ({ isOpen, onClose, userId }) => {
	const [userData, setUserData] = useState(null) // Данные пользователя
	const [selectedFile, setSelectedFile] = useState(null) // Для загрузки аватара
	const [loading, setLoading] = useState(true) // Статус загрузки
	const [openUploadImage, setOpenUploadImage] = useState(false) // Статус загрузки

	const { pageStore } = useStores()
	const toast = useToast()

	// Функция для получения данных пользователя по ID
	const fetchUserData = async id => {
		try {
			const response = await fetch(
				`https://enot-restolounge.ru:8888/users/id/full/${id}`,
				{
					headers: {
						accept: 'application/json',
						Authorization: `Bearer ${pageStore.token}`, // Укажите токен
					},
				}
			)
			if (response.ok) {
				const data = await response.json()
				setUserData(data)
			} else {
				console.error('Ошибка загрузки данных пользователя')
			}
		} catch (error) {
			console.error('Ошибка:', error)
		} finally {
			setLoading(false)
		}
	}

	// Функция для обновления данных пользователя
	const updateUserData = async () => {
		await updateUser(pageStore.token, userData.id, userData)
		pageStore.getFullUsers()
	}

	// Функция для загрузки аватара
	const handleAvatarUpload = async () => {
		if (!selectedFile) {
			toast({
				title: 'Ошибка',
				description: 'Пожалуйста, выберите файл для загрузки.',
				status: 'error',
				duration: 3000,
				isClosable: true,
			})
			return
		}
		const formData = new FormData()
		formData.append('file', selectedFile)

		try {
			const response = await fetch(
				`https://enot-restolounge.ru:8888/image_upload/create_avatar?user_id=${userId}`,
				{
					method: 'POST',
					headers: {
						Authorization: `Bearer ${pageStore.token}`,
					},
					body: formData,
				}
			)

			if (response.ok) {
				toast({
					title: 'Аватар успешно загружен',
					status: 'success',
					duration: 3000,
					isClosable: true,
				})
				setOpenUploadImage(false)
				await fetchUserData(userId)
			} else {
				throw new Error('Ошибка загрузки аватара')
			}
		} catch (error) {
			toast({
				title: 'Ошибка',
				description: `Ошибка при загрузке аватара: ${error.message}`,
				status: 'error',
				duration: 3000,
				isClosable: true,
			})
		}
	}

	useEffect(() => {
		if (userId) {
			fetchUserData(userId)
		}
	}, [userId])

	if (loading) return null // Не показывать модалку, пока идет загрузка данных

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent
				fontFamily={'"Montserrat Alternates", sans-serif !important'}
			>
				<ModalHeader p={4}>Пользователь</ModalHeader>
				<ModalCloseButton color={'gray.400'} />
				<ModalBody p={4}>
					{userData && (
						<FormControl>
							<Flex>
								<Center w={'100%'}>
									<Box position={'relative'}>
										<Image
											src={userData.url}
											alt='User Avatar'
											borderRadius='full'
											boxSize='200px'
											mb={4}
										/>
										<Box
											position={'absolute'}
											borderRadius={50}
											w={50}
											h={50}
											display={'flex'}
											alignItems={'center'}
											justifyContent={'center'}
											right={'11px'}
											bottom={'22px'}
											zIndex={1000}
											backgroundColor={'blue.500'}
											colorScheme='blue'
											cursor={'pointer'}
											onClick={() =>
												setOpenUploadImage(
													!openUploadImage
												)
											}
										>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												height='24px'
												viewBox='0 -960 960 960'
												width='24px'
												fill='#FFFFFF'
											>
												<path d='M200-200h57l391-391-57-57-391 391v57Zm-80 80v-170l528-527q12-11 26.5-17t30.5-6q16 0 31 6t26 18l55 56q12 11 17.5 26t5.5 30q0 16-5.5 30.5T817-647L290-120H120Zm640-584-56-56 56 56Zm-141 85-28-29 57 57-29-28Z' />
											</svg>
										</Box>
									</Box>
								</Center>
							</Flex>
							{openUploadImage ? (
								<ImageUploader
									onChange={e => setSelectedFile(e)}
									onSave={handleAvatarUpload}
								/>
							) : null}
							{/* <Input
								type='file'
								accept='image/*'
								onChange={e =>
									setSelectedFile(e.target.files[0])
								}
							/>
							<Button
								onClick={handleAvatarUpload}
								mt={2}
								colorScheme='blue'
							>
								Загрузить аватар
							</Button> */}
							<Box mt={4}>
								<FloatingInput
									label={'Имя'}
									value={userData.first_name}
									onChange={event =>
										setUserData({
											...userData,
											first_name: event.target.value,
										})
									}
								/>
								<FloatingInput
									label={'Фамилия'}
									value={userData.last_name}
									onChange={event =>
										setUserData({
											...userData,
											last_name: event.target.value,
										})
									}
								/>

								<FloatingInput
									label={'Телефон'}
									value={userData.phone}
									onChange={event =>
										setUserData({
											...userData,
											phone: event.target.value,
										})
									}
								/>
								<FloatingLabelSelect
									label={'Роль'}
									options={[
										{ name: 'Админ', value: 'admin' },
										{ name: 'Юзер', value: 'user' },
									]}
									onChange={event =>
										setUserData({
											...userData,
											role: event.target.value,
										})
									}
									value={userData.role}
									defaultValue={userData.role}
								/>

								<FloatingLabelSelect
									label={'Пол'}
									options={[
										{ name: 'мужской', value: 'male' },
										{ name: 'женский', value: 'female' },
									]}
									onChange={event =>
										setUserData({
											...userData,
											gender: event.target.value,
										})
									}
									value={userData.gender}
									defaultValue={userData.gender}
								/>

								<FloatingInput
									label={'День рождения (если указан)'}
									value={userData.birth_date}
									onChange={event =>
										setUserData({
											...userData,
											birth_date: event.target.value,
										})
									}
									isReadOnly={true}
								/>
							</Box>
							<Button
								colorScheme='blue'
								marginTop={'20px'}
								w={'100%'}
								onClick={() => {
									updateUserData()
										.then(() => {
											toast({
												title: 'Данные обновлены',
												status: 'success',
												duration: 1000,
												isClosable: true,
											})
											onClose()
										})
										.catch(error =>
											toast({
												title: 'Ошибка',
												description: `Ошибка обновления данных: ${error}`,
												status: 'error',
												duration: 1000,
												isClosable: true,
											})
										)
								}}
							>
								Сохранить
							</Button>
						</FormControl>
					)}
				</ModalBody>
			</ModalContent>
		</Modal>
	)
}
const UserTable = observer(() => {
	const [sortField, setSortField] = useState(null) // Поле для сортировки
	const [sortOrder, setSortOrder] = useState('asc') // Направление сортировки
	const [searchTerm, setSearchTerm] = useState('') // Текст для поиска
	const [selectedUserId, setSelectedUserId] = useState(null) // ID выбранного пользователя для модалки
	const { isOpen, onOpen, onClose } = useDisclosure() // Управление состоянием модалки

	const { pageStore } = useStores()
	const toast = useToast()

	useEffect(() => {
		pageStore.getFullUsers()
	}, [pageStore.token])

	// Функция для сортировки массива пользователей
	const sortUsers = (users, field, order) => {
		return [...users].sort((a, b) => {
			const fieldA =
				field === 'full_name'
					? `${a.first_name} ${a.last_name}`.toLowerCase()
					: a[field].toLowerCase()
			const fieldB =
				field === 'full_name'
					? `${b.first_name} ${b.last_name}`.toLowerCase()
					: b[field].toLowerCase()

			if (fieldA < fieldB) {
				return order === 'asc' ? -1 : 1
			}
			if (fieldA > fieldB) {
				return order === 'asc' ? 1 : -1
			}
			return 0
		})
	}

	// Фильтрация по имени, фамилии или телефону
	const filteredUsers = pageStore.users.filter(user => {
		const fullName = `${user.first_name} ${user.last_name}`.toLowerCase()
		return (
			fullName.includes(searchTerm.toLowerCase()) ||
			user.phone.includes(searchTerm)
		)
	})

	// Сортировка пользователей в зависимости от выбранного поля
	const sortedUsers = sortField
		? sortUsers(filteredUsers, sortField, sortOrder)
		: filteredUsers

	// Переключение сортировки
	const toggleSort = field => {
		if (sortField === field) {
			setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
		} else {
			setSortField(field)
			setSortOrder('asc')
		}
	}

	// Функция для открытия модалки
	const handleEditUser = userId => {
		setSelectedUserId(userId)
		onOpen()
	}
	const handleConfirmDelete = (name, user_id) => {
		// Показываем кастомный тост с кнопками "Подтвердить" и "Отменить"
		toast({
			position: 'bottom',
			duration: null, // Тост будет оставаться на экране до действия
			isClosable: true,
			render: ({ onClose }) => (
				<VStack
					bg='gray.700'
					color='white'
					p={4}
					rounded='md'
					spacing={3}
					align='stretch'
				>
					<Text>
						Вы уверены, что хотите удалить пользователя {name}?
					</Text>
					<VStack spacing={2} align='stretch'>
						<Button
							colorScheme='green'
							onClick={async () => {
								const response = await fetch(
									`https://enot-restolounge.ru:8888/users/${user_id}`,
									{
										method: 'DELETE',
										headers: {
											accept: 'application/json',
											Authorization: `Bearer ${pageStore.token}`,
										},
									}
								)

								onClose() // Закрытие тоста
								pageStore.getFullUsers()
								toast({
									title: 'Пользователь удален',
									status: 'success',
									duration: 3000,
									isClosable: true,
								})
							}}
						>
							Подтвердить
						</Button>
						<Button colorScheme='red' onClick={onClose}>
							Отменить
						</Button>
					</VStack>
				</VStack>
			),
		})
	}

	return (
		<Box
			p={4}
			backgroundColor={'white'}
			width={'100%'}
			borderRadius={'12px'}
			overflowX={'scroll'}
			fontFamily='"Montserrat Alternates", sans-serif'
		>
			<Heading as='h2' size='lg' mb={4}>
				Таблица пользователей
			</Heading>
			<HStack
				width={'100%'}
				justify={'space-between'}
				align={'center'}
				mb={4}
			>
				<Input
					placeholder='Поиск по имени, фамилии или телефону'
					value={searchTerm}
					maxW={'40vw'}
					onChange={e => setSearchTerm(e.target.value)}
				/>
				<CreateUserModal />
			</HStack>

			{/* Поиск по телефону, имени или фамилии */}

			<Table variant='simple'>
				<Thead>
					<Tr>
						<Th>
							<Button
								variant='link'
								onClick={() => toggleSort('full_name')}
							>
								Имя и фамилия{' '}
								{sortField === 'full_name' &&
									(sortOrder === 'asc' ? '↑' : '↓')}
							</Button>
						</Th>
						<Th>
							<Button
								variant='link'
								onClick={() => toggleSort('role')}
							>
								Роль{' '}
								{sortField === 'role' &&
									(sortOrder === 'asc' ? '↑' : '↓')}
							</Button>
						</Th>
						<Th>
							<Button
								variant='link'
								onClick={() => toggleSort('gender')}
							>
								Пол{' '}
								{sortField === 'gender' &&
									(sortOrder === 'asc' ? '↑' : '↓')}
							</Button>
						</Th>
						<Th>Телефон</Th>
						<Th>Действия</Th>
						<Th>Удаление</Th>
					</Tr>
				</Thead>
				<Tbody>
					{sortedUsers.map(user => (
						<Tr key={user.id}>
							<Td>{`${user.first_name} ${user.last_name}`}</Td>
							<Td>{user.role}</Td>
							<Td>{user.gender}</Td>
							<Td>{formatPhoneNumber(user.phone)}</Td>
							<Td>
								<IconButton
									icon={<EditIcon />}
									onClick={() => handleEditUser(user.id)}
									aria-label='Edit User'
								/>
							</Td>
							<Td>
								<IconButton
									icon={<DeleteIcon />}
									onClick={() =>
										handleConfirmDelete(
											`${user.first_name} ${user.last_name}`,
											user.id
										)
									}
									aria-label='Delete user'
								/>
							</Td>
						</Tr>
					))}
				</Tbody>
			</Table>

			{/* Модальное окно для редактирования пользователя */}
			<UserModal
				isOpen={isOpen}
				onClose={onClose}
				userId={selectedUserId}
			/>
		</Box>
	)
})

export default UserTable
