import { Box, FormControl, FormLabel, Select } from '@chakra-ui/react'

const activeLabelStyles = {
	transform: 'scale(0.85) translateY(-24px)',
}

export default function FloatingLabelSelect({
	options,
	onChange,
	defaultValue,
	label,
	value,
}) {
	return (
		<Box py={4}>
			<FormControl variant='floating' id='role'>
				<Select
					placeholder=' '
					onChange={onChange}
					defaultValue={defaultValue}
					value={value}
				>
					{options.map(option => (
						<option value={option.value}>{option.name}</option>
					))}
				</Select>
				<FormLabel>{label}</FormLabel>
			</FormControl>
		</Box>
	)
}
