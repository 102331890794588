import {
	Button,
	FormControl,
	FormLabel,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	useToast,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useStores } from '../../store/store_context'

const HallModal = ({ isOpen, onClose, hallId }) => {
	const [hall, setHall] = useState({})
	const [selectedFile, setSelectedFile] = useState(null)
	const { pageStore } = useStores()
	const toast = useToast()

	// Получение данных зала для редактирования
	useEffect(() => {
		const fetchHall = async () => {
			try {
				const response = await fetch(
					`https://enot-restolounge.ru:8888/halls/id/full/${hallId}`,
					{
						headers: {
							accept: 'application/json',
							Authorization: `Bearer ${pageStore.token}`,
						},
					}
				)

				if (response.ok) {
					const data = await response.json()
					setHall(data)
				}
			} catch (error) {
				console.error('Ошибка:', error)
			}
		}

		if (hallId) {
			fetchHall()
		}
	}, [hallId])

	// Функция для обновления изображения зала
	const updateHallImage = async () => {
		if (!selectedFile) {
			toast({
				title: 'Ошибка',
				description: 'Выберите файл для загрузки',
				status: 'error',
				duration: 5000,
				isClosable: true,
			})
			return
		}

		const formData = new FormData()
		formData.append('file', selectedFile)

		try {
			const response = await fetch(
				`https://enot-restolounge.ru:8888/image_upload/update_hall?hall_name=${encodeURIComponent(
					hall.name
				)}`,
				{
					method: 'POST',
					headers: {
						accept: 'application/json',
						Authorization: `Bearer ${pageStore.token}`,
					},
					body: formData,
				}
			)

			if (response.ok) {
				const result = await response.json()
				toast({
					title: 'Успех',
					description: 'Изображение зала обновлено',
					status: 'success',
					duration: 5000,
					isClosable: true,
				})
				onClose()
			} else {
				toast({
					title: 'Ошибка',
					description: 'Не удалось обновить изображение',
					status: 'error',
					duration: 5000,
					isClosable: true,
				})
			}
		} catch (error) {
			console.error('Ошибка обновления изображения:', error)
			toast({
				title: 'Ошибка',
				description: 'Что-то пошло не так',
				status: 'error',
				duration: 5000,
				isClosable: true,
			})
		}
	}

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Редактирование зала</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<FormControl>
						<FormLabel>Название зала</FormLabel>
						<Input
							value={hall.name || ''}
							onChange={e =>
								setHall({ ...hall, name: e.target.value })
							}
						/>
					</FormControl>
					<FormControl mt={4}>
						<FormLabel>URL изображения</FormLabel>
						<Input
							value={hall.url || ''}
							onChange={e =>
								setHall({ ...hall, url: e.target.value })
							}
						/>
					</FormControl>
					<FormControl mt={4}>
						<FormLabel>Загрузить новое изображение</FormLabel>
						<Input
							type='file'
							onChange={e => setSelectedFile(e.target.files[0])}
						/>
					</FormControl>
				</ModalBody>

				<ModalFooter>
					<Button colorScheme='blue' mr={3} onClick={updateHallImage}>
						Обновить изображение
					</Button>
					<Button variant='ghost' onClick={onClose}>
						Отмена
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default HallModal
