import {
	Box,
	Button,
	Checkbox,
	CheckboxGroup,
	FormControl,
	FormLabel,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Select,
	Text,
	useToast,
	VStack,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useStores } from '../../store/store_context'

const OrderModal = ({ isOpen, onClose, orderId, updateOrders }) => {
	const [order, setOrder] = useState(null)
	const [users, setUsers] = useState([])
	const [products, setProducts] = useState([])
	const [dishes, setDishes] = useState([])
	const [hookahs, setHookahs] = useState([])
	const [selectedDishes, setSelectedDishes] = useState([])
	const [selectedHookahs, setSelectedHookahs] = useState([])
	const toast = useToast()
	const { pageStore } = useStores()

	useEffect(() => {
		const fetchOrder = async () => {
			try {
				const response = await fetch(
					`https://enot-restolounge.ru:8888/orders/id/full/${orderId}`,
					{
						headers: {
							accept: 'application/json',
							Authorization: `Bearer ${pageStore.token}`,
						},
					}
				)
				if (response.ok) {
					const data = await response.json()
					setOrder(data)
					fetchProducts(data.id)
				} else {
					toast({
						title: 'Ошибка',
						description: 'Ошибка загрузки данных заказа',
						status: 'error',
						duration: 3000,
						isClosable: true,
					})
				}
			} catch (error) {
				console.error('Ошибка:', error)
				toast({
					title: 'Ошибка',
					description: `Ошибка: ${error.message}`,
					status: 'error',
					duration: 3000,
					isClosable: true,
				})
			}
		}

		const fetchUsers = async () => {
			try {
				const response = await fetch(
					'https://enot-restolounge.ru:8888/users/full',
					{
						headers: {
							accept: 'application/json',
							Authorization: `Bearer ${pageStore.token}`,
						},
					}
				)
				if (response.ok) {
					const data = await response.json()
					setUsers(data)
				} else {
					toast({
						title: 'Ошибка',
						description: 'Ошибка загрузки пользователей',
						status: 'error',
						duration: 3000,
						isClosable: true,
					})
				}
			} catch (error) {
				console.error('Ошибка:', error)
				toast({
					title: 'Ошибка',
					description: `Ошибка: ${error.message}`,
					status: 'error',
					duration: 3000,
					isClosable: true,
				})
			}
		}

		const fetchProducts = async orderId => {
			try {
				const response = await fetch(
					`https://enot-restolounge.ru:8888/orders_products/`,
					{
						headers: {
							accept: 'application/json',
							Authorization: `Bearer ${pageStore.token}`,
						},
					}
				)
				if (response.ok) {
					const data = await response.json()
					const filtered = data.filter(
						elem => elem.order_id == orderId
					)
					setProducts(filtered.map(elem => elem.product))
				} else {
					toast({
						title: 'Ошибка',
						description: 'Ошибка загрузки продуктов',
						status: 'error',
						duration: 3000,
						isClosable: true,
					})
				}
			} catch (error) {
				console.error('Ошибка:', error)
				toast({
					title: 'Ошибка',
					description: `Ошибка: ${error.message}`,
					status: 'error',
					duration: 3000,
					isClosable: true,
				})
			}
		}

		const fetchDishesAndHookahs = async () => {
			try {
				const dishesResponse = await fetch(
					'https://enot-restolounge.ru:8888/dishes/full',
					{
						headers: {
							accept: 'application/json',
							Authorization: `Bearer ${pageStore.token}`,
						},
					}
				)
				const hookahsResponse = await fetch(
					'https://enot-restolounge.ru:8888/hookahs/full',
					{
						headers: {
							accept: 'application/json',
							Authorization: `Bearer ${pageStore.token}`,
						},
					}
				)

				if (dishesResponse.ok && hookahsResponse.ok) {
					const dishesData = await dishesResponse.json()
					const hookahsData = await hookahsResponse.json()
					setDishes(dishesData)
					setHookahs(hookahsData)
				} else {
					toast({
						title: 'Ошибка',
						description: 'Ошибка загрузки блюд и кальянов',
						status: 'error',
						duration: 3000,
						isClosable: true,
					})
				}
			} catch (error) {
				console.error('Ошибка:', error)
				toast({
					title: 'Ошибка',
					description: `Ошибка: ${error.message}`,
					status: 'error',
					duration: 3000,
					isClosable: true,
				})
			}
		}

		if (orderId) {
			fetchOrder()
			fetchUsers()
			fetchDishesAndHookahs()
		}
	}, [orderId, toast, pageStore.token])

	const handleSave = async () => {
		const updatedOrder = {
			...order,
			table_number: order.table_number
				? order.table_number
				: order.table.number,
			user_id: order.user_id ? order.user_id : order.user.id,
		}

		try {
			const response = await fetch(
				`https://enot-restolounge.ru:8888/orders/${orderId}`,
				{
					method: 'PUT',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${pageStore.token}`,
					},
					body: JSON.stringify(updatedOrder),
				}
			)

			if (response.ok) {
				// Добавляем выбранные блюда и кальяны в заказ
				await fetch(
					`https://enot-restolounge.ru:8888/orders/add_dish_or_hookah_to_order/${orderId}`,
					{
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
							Authorization: `Bearer ${pageStore.token}`,
						},
						body: JSON.stringify({
							dishes: selectedDishes,
							hookahs: selectedHookahs,
						}),
					}
				)

				toast({
					title: 'Успех',
					description: 'Заказ обновлён',
					status: 'success',
					duration: 3000,
					isClosable: true,
				})
				updateOrders()
				onClose()
			} else {
				toast({
					title: 'Ошибка',
					description: 'Ошибка сохранения заказа',
					status: 'error',
					duration: 3000,
					isClosable: true,
				})
			}
		} catch (error) {
			console.error('Ошибка:', error)
			toast({
				title: 'Ошибка',
				description: `Ошибка: ${error.message}`,
				status: 'error',
				duration: 3000,
				isClosable: true,
			})
		}
	}

	if (!order) return null

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent
				fontFamily={'"Montserrat Alternates", sans-serif !important'}
			>
				<ModalHeader>Редактирование заказа</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<FormControl mb={4}>
						<FormLabel>Дата начала</FormLabel>
						<Input
							type='datetime-local'
							value={order.date_start.substring(0, 16)}
							onChange={e =>
								setOrder({
									...order,
									date_start: e.target.value,
								})
							}
						/>
					</FormControl>
					<FormControl mb={4}>
						<FormLabel>Дата окончания</FormLabel>
						<Input
							type='datetime-local'
							value={order.date_finish.substring(0, 16)}
							onChange={e =>
								setOrder({
									...order,
									date_finish: e.target.value,
								})
							}
						/>
					</FormControl>
					<FormControl mb={4}>
						<FormLabel>Номер стола</FormLabel>
						<Input
							type='number'
							value={order.table.number}
							onChange={e =>
								setOrder({
									...order,
									table_number: e.target.value,
									table: { number: e.target.value },
								})
							}
						/>
					</FormControl>
					<Text>
						Количество мест: {order.table.seats}. Статус стола:{' '}
						{order.table.status}
					</Text>

					<FormControl mb={4}>
						<FormLabel>Депозит</FormLabel>
						<Input
							type='number'
							value={order.deposit_amount}
							onChange={e =>
								setOrder({
									...order,
									deposit_amount: e.target.value,
								})
							}
						/>
					</FormControl>
					<FormControl mb={4}>
						<FormLabel>Пользователь</FormLabel>
						<Select
							value={order.user_id}
							onChange={e =>
								setOrder({ ...order, user_id: e.target.value })
							}
						>
							{users.map(user => (
								<option key={user.id} value={user.id}>
									{`${user.first_name} ${user.last_name}`}
								</option>
							))}
						</Select>
					</FormControl>
					<FormControl mb={4}>
						<FormLabel>Продукты в заказе</FormLabel>
						<Box>
							{products.length > 0 ? (
								products
									.filter(elem => elem != false)
									.map(item => (
										<Box
											key={item.id}
											mb={2}
											p={2}
											border='1px'
											borderRadius='md'
											borderColor='gray.200'
										>
											<Text fontWeight='bold'>
												{item.name}
											</Text>
											<Text>{item.description}</Text>
											<Text>Цена: {item.price} ₽</Text>
											<Text>
												Вес/Объём:{' '}
												{item.weight_or_volume}
											</Text>
											<Text>
												Время приготовления:{' '}
												{item.preparation_time} мин
											</Text>
										</Box>
									))
							) : (
								<Text>Нет продуктов в заказе</Text>
							)}
						</Box>
					</FormControl>
					<FormControl mb={4}>
						<FormLabel>Добавить блюда</FormLabel>
						<CheckboxGroup onChange={setSelectedDishes}>
							<VStack align='start'>
								{dishes.map(dish => (
									<Checkbox
										key={dish.id}
										value={dish.id.toString()}
									>
										{`${dish.name} - ${dish.price} ₽ (${dish.weight_or_volume} г)`}
									</Checkbox>
								))}
							</VStack>
						</CheckboxGroup>
					</FormControl>

					{/* Выбор кальянов */}
					<FormControl mb={4}>
						<FormLabel>Добавить кальяны</FormLabel>
						<CheckboxGroup onChange={setSelectedHookahs}>
							<VStack align='start'>
								{hookahs.map(hookah => (
									<Checkbox
										key={hookah.id}
										value={hookah.id.toString()}
									>
										{`${hookah.name} - ${hookah.price} ₽`}
									</Checkbox>
								))}
							</VStack>
						</CheckboxGroup>
					</FormControl>
				</ModalBody>
				<ModalFooter>
					<Button colorScheme='blue' onClick={handleSave}>
						Сохранить
					</Button>
					<Button onClick={onClose} ml={3}>
						Закрыть
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default OrderModal
