import {
	Container,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	VStack,
} from '@chakra-ui/react'
import React from 'react'
import CompanyTable from '../components/company_tab/company_table'
import DishesTable from '../components/dishes_tab/dishes_table'
import HallsTable from '../components/halls_tab/halls_table'
import HookahsTable from '../components/hookah_tab/hookah_table'
import OrdersTable from '../components/orders_tab/order_table'
import UsersTab from '../components/users_tab/users_tab'
const MainPage = () => {
	return (
		<Container maxW='container.xl' p={4} alignItems='flex-start'>
			<Tabs variant='soft-rounded'>
				<TabList mb={4}>
					<Tab color={'white'}>Пользователи</Tab>
					<Tab color={'white'}>Заказы</Tab>
					<Tab color={'white'}>Блюда</Tab>
					<Tab color={'white'}>Залы</Tab>
					<Tab color={'white'}>Кальяны</Tab>
					<Tab color={'white'}>Инфо о компании</Tab>
				</TabList>

				<TabPanels>
					<TabPanel p={0}>
						<UsersTab />
					</TabPanel>
					<TabPanel p={0}>
						<VStack width={'100%'}>
							<OrdersTable />
						</VStack>
					</TabPanel>
					<TabPanel p={0}>
						<VStack width={'100%'}>
							<DishesTable />
						</VStack>
					</TabPanel>
					<TabPanel p={0}>
						<VStack width={'100%'}>
							<HallsTable />
						</VStack>
					</TabPanel>
					<TabPanel p={0}>
						<VStack width={'100%'}>
							<HookahsTable />
						</VStack>
					</TabPanel>
					<TabPanel p={0}>
						<VStack width={'100%'}>
							<CompanyTable />
						</VStack>
					</TabPanel>
				</TabPanels>
			</Tabs>
		</Container>
	)
}

export default MainPage
