import { Box, FormControl, FormLabel, Input } from '@chakra-ui/react'

const FloatingInput = ({ label, value, onChange, placeholder, isReadOnly }) => {
	return (
		<Box py={4}>
			<FormControl variant='floating' id='first-name'>
				<Input
					className='peer'
					placeholder={placeholder}
					value={value}
					onChange={onChange}
					isReadOnly={isReadOnly}
				/>

				<FormLabel>{label}</FormLabel>
				{/* <FormHelperText>Keep it very short and sweet!</FormHelperText>
				<FormErrorMessage>Your First name is invalid</FormErrorMessage> */}
			</FormControl>
		</Box>
	)
}

export default FloatingInput
