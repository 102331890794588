import { makeAutoObservable } from 'mobx'
import { getUsersFull } from '../requests'

class PageStore {
	token = null
	users = []
	user = {}

	constructor() {
		makeAutoObservable(this)
	}
	updateCart = newCart => {
		this.cart = newCart
	}
	signIn = async values => {
		const response = await fetch(
			`https://enot-restolounge.ru:8888/signin/`,
			{
				method: 'POST',
				headers: {
					accept: 'application/json',
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(values),
			}
		)
		const result = await response.json()
		this.token = result.access_token
		return [result, response]
	}
	getFullUsers = async () => {
		this.users = await getUsersFull(this.token)
	}

	getCurrentAuthUser = async () => {
		const url = `https://enot-restolounge.ru:8888/get_current_auth_user/?token=${this.token}`

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${this.token}`,
			},
		})
		const result = await response.json()
		this.user = result
	}
}

export default PageStore
