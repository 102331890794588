import {
	Box,
	Button,
	CloseButton,
	HStack,
	Image,
	Text,
	VStack,
} from '@chakra-ui/react'
import { useState } from 'react'
import { useDropzone } from 'react-dropzone'

export default function ImageUploader({ onChange, onSave }) {
	const [selectedImage, setSelectedImage] = useState(null)

	// const onDrop = acceptedFiles => {
	// 	const newImages = acceptedFiles.map(file => {
	// 		return Object.assign(file, {
	// 			preview: URL.createObjectURL(file), // Создаем объект URL для отображения
	// 		})
	// 	})
	// 	setSelectedImages([...selectedImages, ...newImages]) // Добавляем новые изображения
	// 	onChange(acceptedFiles[0])
	// }
	const onDrop = acceptedFiles => {
		const file = acceptedFiles[0]
		if (file) {
			const preview = URL.createObjectURL(file)
			setSelectedImage({ file, preview })
			onChange(file)
		}
	}

	const removeImage = () => {
		onChange(null)
		setSelectedImage(null)
	}

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		accept: 'image/*',
		multiple: false,
	})

	return (
		<VStack spacing={4}>
			{/* Зона для загрузки */}
			<Box
				{...getRootProps()}
				p={4}
				border='2px dashed gray'
				borderRadius='md'
				textAlign='center'
				cursor='pointer'
				_hover={{ bg: 'gray.50' }}
				w='100%'
			>
				<input {...getInputProps()} />
				<Text>
					Перетащите изображение сюда или нажмите для загрузки
				</Text>
			</Box>
			{/* Превью загруженных изображений
			<HStack spacing={4} wrap='wrap'>
				{selectedImages.map((file, index) => (
					<Box key={index} position='relative'>
						<Image
							src={file.preview}
							boxSize='100px'
							borderRadius='md'
						/>
						<CloseButton
							position='absolute'
							top={1}
							right={1}
							onClick={() => removeImage(index)}
							bg='white'
							borderRadius='full'
						/>
					</Box>
				))}
			</HStack> */}

			{/* Превью загруженного изображения */}
			{selectedImage && (
				<HStack spacing={4}>
					<Box position='relative'>
						<Image
							src={selectedImage.preview}
							boxSize='100px'
							borderRadius='md'
						/>
						<CloseButton
							position='absolute'
							top={1}
							right={1}
							onClick={removeImage}
							bg='white'
							borderRadius='full'
						/>
					</Box>
				</HStack>
			)}
			{/* Кнопка загрузки */}
			{selectedImage && (
				<Button colorScheme='blue' onClick={onSave} w={'100%'}>
					Загрузить аватар
				</Button>
			)}
		</VStack>
	)
}
