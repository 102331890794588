import { EditIcon } from '@chakra-ui/icons'
import {
	Box,
	Button,
	IconButton,
	Input,
	Table,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
	useDisclosure,
	useToast,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useStores } from '../../store/store_context' // Хук для работы с состоянием
import CompanyModal from './company_modal' // Модальное окно для редактирования компании

const CompanyTable = () => {
	const [companies, setCompanies] = useState([])
	const [sortField, setSortField] = useState(null)
	const [sortOrder, setSortOrder] = useState('asc')
	const [selectedCompanyId, setSelectedCompanyId] = useState(null)
	const [searchTerm, setSearchTerm] = useState('') // Состояние для термина поиска
	const { isOpen, onOpen, onClose } = useDisclosure()
	const toast = useToast()
	const { pageStore } = useStores()

	useEffect(() => {
		const fetchCompanies = async () => {
			try {
				const response = await fetch(
					'https://enot-restolounge.ru:8888/companys/',
					{
						headers: {
							accept: 'application/json',
							Authorization: `Bearer ${pageStore.token}`,
						},
					}
				)
				if (response.ok) {
					const data = await response.json()
					setCompanies(data)
				} else {
					toast({
						title: 'Ошибка',
						description: 'Ошибка загрузки компаний',
						status: 'error',
						duration: 3000,
						isClosable: true,
					})
				}
			} catch (error) {
				console.error('Ошибка:', error)
				toast({
					title: 'Ошибка',
					description: `Ошибка: ${error.message}`,
					status: 'error',
					duration: 3000,
					isClosable: true,
				})
			}
		}

		fetchCompanies()
	}, [pageStore.token])

	const sortCompanies = (companies, field, order) => {
		return [...companies].sort((a, b) => {
			let fieldA = a[field]
			let fieldB = b[field]

			if (typeof fieldA === 'string') fieldA = fieldA.toLowerCase()
			if (typeof fieldB === 'string') fieldB = fieldB.toLowerCase()

			if (fieldA < fieldB) {
				return order === 'asc' ? -1 : 1
			}
			if (fieldA > fieldB) {
				return order === 'asc' ? 1 : -1
			}
			return 0
		})
	}

	const sortedCompanies = sortField
		? sortCompanies(companies, sortField, sortOrder)
		: companies

	const filteredCompanies = sortedCompanies.filter(
		company =>
			company.address.toLowerCase().includes(searchTerm.toLowerCase()) ||
			company.phone.includes(searchTerm)
	)

	const toggleSort = field => {
		if (sortField === field) {
			setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
		} else {
			setSortField(field)
			setSortOrder('asc')
		}
	}

	const handleEditCompany = companyId => {
		setSelectedCompanyId(companyId)
		onOpen()
	}

	return (
		<Box
			p={4}
			backgroundColor={'white'}
			width={'100%'}
			borderRadius={'12px'}
		>
			<Input
				placeholder='Поиск по адресу или телефону'
				mb={4}
				onChange={e => setSearchTerm(e.target.value)} // Установка термина поиска
			/>

			<Table variant='simple'>
				<Thead>
					<Tr>
						<Th>
							<Button
								variant='link'
								onClick={() => toggleSort('address')}
							>
								Адрес{' '}
								{sortField === 'address' &&
									(sortOrder === 'asc' ? '↑' : '↓')}
							</Button>
						</Th>
						<Th>
							<Button
								variant='link'
								onClick={() => toggleSort('phone')}
							>
								Телефон{' '}
								{sortField === 'phone' &&
									(sortOrder === 'asc' ? '↑' : '↓')}
							</Button>
						</Th>
						<Th>Рабочие часы</Th>
						<Th>Социальные сети</Th>
						<Th>Действия</Th>
					</Tr>
				</Thead>
				<Tbody>
					{filteredCompanies.map(company => (
						<Tr key={company.id}>
							<Td>{company.address}</Td>
							<Td>{company.phone}</Td>
							<Td>
								{company.working_hours
									? JSON.parse(company.working_hours)?.MonFri
											?.open +
									  ' - ' +
									  JSON.parse(company.working_hours)?.MonFri
											?.close
									: 'Нет данных'}
							</Td>
							<Td>{company.social_media || 'Нет данных'}</Td>
							<Td>
								<IconButton
									icon={<EditIcon />}
									onClick={() =>
										handleEditCompany(company.id)
									}
									aria-label='Edit Company'
								/>
							</Td>
						</Tr>
					))}
				</Tbody>
			</Table>

			{/* Модальное окно для редактирования компании */}
			<CompanyModal
				isOpen={isOpen}
				onClose={onClose}
				companyId={selectedCompanyId}
			/>
		</Box>
	)
}

export default CompanyTable
